import localizeFilter from '@/filter/localize.filter'
import changeToDots from '@/filter/dots.filter'
export default function  addMetalRates(){
    if(this.$data.mr != '' && !Number.isInteger(parseInt(this.$data.mr.replace('.', ',')))){
        UIkit.notification({message: localizeFilter('Pt_non_integer')})
    }
    else if(this.$data.mi != '' && !Number.isInteger(parseInt(this.$data.mi.replace('.', ',')))){
        UIkit.notification({message: localizeFilter('Pd_non_integer')})
    }
    else if(this.$data.mb != '' && !Number.isInteger(parseInt(this.$data.mb.replace('.', ',')))){
        UIkit.notification({message: localizeFilter('Rh_non_integer')})
    }
    else{
        this.$Progress.start()
        axios.get('/dashboard.php',
            {
                params:
                {
                    type:31,
                    mr: changeToDots(this.$data.mr),
                    mi: changeToDots(this.$data.mi),
                    mb: changeToDots(this.$data.mb)
                }
            })
            .then(response => {
                if(response.data.lenght != 0 || response.data != -10){
                    UIkit.notification({message: localizeFilter('Changed')})
                }else
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}
