<template>
    <div class="uk-grid uk-grid-medium" data-uk-grid>
        <!-- panel -->
        <div class="uk-width-1-1">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-body">
                    <div class="form-container">
                       <form @submit.prevent="addMetalRates">
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-4@s">
                                <label class="uk-form-label ">{{"Металлический Россия" | localize}}</label>
                                <input class="uk-input" type="text" v-model='mr' required>
                            </div>
                            <div class="uk-width-1-4@s">
                                <label class="uk-form-label ">{{"Металлический импорт" | localize}}</label>
                                <input class="uk-input" type="text" v-model='mi' required>
                            </div>
                            <div class="uk-width-1-4@s">
                                <label class="uk-form-label ">{{"Металлический BMW" | localize}}</label>
                                <input class="uk-input" type="text" v-model='mb' required>
                            </div>
                        </div>
                        <div class="uk-width-1-1@s uk-margin">
                            <button class="uk-button uk-button-primary uk-border-rounded uk-width-1-1@s uk-width-1-3@l uk-align-center uk-margin-medium-top">{{"Save" | localize}}</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import getMetalRates from '@/config/GetMetalRates.dashboard'
import localizeFilter from '@/filter/localize.filter'
import addMetalRates from '@/config/AddMetalRates.dashboard'

export default {
    data: () => ({
        mr: '',
        mi:'',
        mb:'',
    }),
    methods:{
        getMetalRates, localizeFilter, addMetalRates,
    },
    beforeMount(){
        this.getMetalRates()
    }
}
</script>
