import localizeFilter from '@/filter/localize.filter'
export default function  getMetalRates(){
    this.$Progress.start()
    return axios.get('/dashboard.php',
        {
            params:
            {
                //type: 27 get 10 last currencies
                type:30
            }
        })
        .then(response => {
            if(response.data.lenght != 0 || response.data != -10){
               // this.$data.currency = response.data;
               this.$data.mr = response.data[0].russia
               this.$data.mi = response.data[0].import
               this.$data.mb = response.data[0].bmw
            }else
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}
